// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-armatura-js": () => import("./../../../src/pages/armatura.js" /* webpackChunkName: "component---src-pages-armatura-js" */),
  "component---src-pages-betoni-js": () => import("./../../../src/pages/betoni.js" /* webpackChunkName: "component---src-pages-betoni-js" */),
  "component---src-pages-bllokat-js": () => import("./../../../src/pages/bllokat.js" /* webpackChunkName: "component---src-pages-bllokat-js" */),
  "component---src-pages-dritaret-js": () => import("./../../../src/pages/dritaret.js" /* webpackChunkName: "component---src-pages-dritaret-js" */),
  "component---src-pages-fasada-e-jashtme-js": () => import("./../../../src/pages/fasada-e-jashtme.js" /* webpackChunkName: "component---src-pages-fasada-e-jashtme-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katallogu-js": () => import("./../../../src/pages/katallogu.js" /* webpackChunkName: "component---src-pages-katallogu-js" */),
  "component---src-pages-lokacioni-js": () => import("./../../../src/pages/lokacioni.js" /* webpackChunkName: "component---src-pages-lokacioni-js" */),
  "component---src-pages-materialet-js": () => import("./../../../src/pages/materialet.js" /* webpackChunkName: "component---src-pages-materialet-js" */),
  "component---src-pages-suvatimi-i-brendshem-js": () => import("./../../../src/pages/suvatimi-i-brendshem.js" /* webpackChunkName: "component---src-pages-suvatimi-i-brendshem-js" */),
  "component---src-pages-tipia-1-a-js": () => import("./../../../src/pages/tipia1-a.js" /* webpackChunkName: "component---src-pages-tipia-1-a-js" */),
  "component---src-pages-tipia-1-b-js": () => import("./../../../src/pages/tipia1-b.js" /* webpackChunkName: "component---src-pages-tipia-1-b-js" */),
  "component---src-pages-tipia-10-a-js": () => import("./../../../src/pages/tipia10-a.js" /* webpackChunkName: "component---src-pages-tipia-10-a-js" */),
  "component---src-pages-tipia-10-b-js": () => import("./../../../src/pages/tipia10-b.js" /* webpackChunkName: "component---src-pages-tipia-10-b-js" */),
  "component---src-pages-tipia-2-a-js": () => import("./../../../src/pages/tipia2-a.js" /* webpackChunkName: "component---src-pages-tipia-2-a-js" */),
  "component---src-pages-tipia-2-b-js": () => import("./../../../src/pages/tipia2-b.js" /* webpackChunkName: "component---src-pages-tipia-2-b-js" */),
  "component---src-pages-tipia-3-a-js": () => import("./../../../src/pages/tipia3-a.js" /* webpackChunkName: "component---src-pages-tipia-3-a-js" */),
  "component---src-pages-tipia-3-b-js": () => import("./../../../src/pages/tipia3-b.js" /* webpackChunkName: "component---src-pages-tipia-3-b-js" */),
  "component---src-pages-tipia-4-a-js": () => import("./../../../src/pages/tipia4-a.js" /* webpackChunkName: "component---src-pages-tipia-4-a-js" */),
  "component---src-pages-tipia-4-b-js": () => import("./../../../src/pages/tipia4-b.js" /* webpackChunkName: "component---src-pages-tipia-4-b-js" */),
  "component---src-pages-tipia-5-a-js": () => import("./../../../src/pages/tipia5-a.js" /* webpackChunkName: "component---src-pages-tipia-5-a-js" */),
  "component---src-pages-tipia-5-b-js": () => import("./../../../src/pages/tipia5-b.js" /* webpackChunkName: "component---src-pages-tipia-5-b-js" */),
  "component---src-pages-tipia-6-a-js": () => import("./../../../src/pages/tipia6-a.js" /* webpackChunkName: "component---src-pages-tipia-6-a-js" */),
  "component---src-pages-tipia-6-b-js": () => import("./../../../src/pages/tipia6-b.js" /* webpackChunkName: "component---src-pages-tipia-6-b-js" */),
  "component---src-pages-tipia-7-a-js": () => import("./../../../src/pages/tipia7-a.js" /* webpackChunkName: "component---src-pages-tipia-7-a-js" */),
  "component---src-pages-tipia-7-b-js": () => import("./../../../src/pages/tipia7-b.js" /* webpackChunkName: "component---src-pages-tipia-7-b-js" */),
  "component---src-pages-tipia-8-a-js": () => import("./../../../src/pages/tipia8-a.js" /* webpackChunkName: "component---src-pages-tipia-8-a-js" */),
  "component---src-pages-tipia-8-b-js": () => import("./../../../src/pages/tipia8-b.js" /* webpackChunkName: "component---src-pages-tipia-8-b-js" */),
  "component---src-pages-tipia-9-a-js": () => import("./../../../src/pages/tipia9-a.js" /* webpackChunkName: "component---src-pages-tipia-9-a-js" */),
  "component---src-pages-tipia-9-b-js": () => import("./../../../src/pages/tipia9-b.js" /* webpackChunkName: "component---src-pages-tipia-9-b-js" */),
  "component---src-pages-tipic-1-js": () => import("./../../../src/pages/tipic1.js" /* webpackChunkName: "component---src-pages-tipic-1-js" */),
  "component---src-pages-tipic-2-js": () => import("./../../../src/pages/tipic2.js" /* webpackChunkName: "component---src-pages-tipic-2-js" */),
  "component---src-pages-tipic-3-js": () => import("./../../../src/pages/tipic3.js" /* webpackChunkName: "component---src-pages-tipic-3-js" */),
  "component---src-pages-tipic-4-js": () => import("./../../../src/pages/tipic4.js" /* webpackChunkName: "component---src-pages-tipic-4-js" */),
  "component---src-pages-tipic-5-js": () => import("./../../../src/pages/tipic5.js" /* webpackChunkName: "component---src-pages-tipic-5-js" */),
  "component---src-pages-tipic-6-js": () => import("./../../../src/pages/tipic6.js" /* webpackChunkName: "component---src-pages-tipic-6-js" */),
  "component---src-pages-tipic-7-js": () => import("./../../../src/pages/tipic7.js" /* webpackChunkName: "component---src-pages-tipic-7-js" */),
  "component---src-pages-tipic-8-js": () => import("./../../../src/pages/tipic8.js" /* webpackChunkName: "component---src-pages-tipic-8-js" */),
  "component---src-pages-tipid-1-js": () => import("./../../../src/pages/tipid1.js" /* webpackChunkName: "component---src-pages-tipid-1-js" */),
  "component---src-pages-tipid-2-js": () => import("./../../../src/pages/tipid2.js" /* webpackChunkName: "component---src-pages-tipid-2-js" */),
  "component---src-pages-tipid-3-js": () => import("./../../../src/pages/tipid3.js" /* webpackChunkName: "component---src-pages-tipid-3-js" */),
  "component---src-pages-tipid-4-js": () => import("./../../../src/pages/tipid4.js" /* webpackChunkName: "component---src-pages-tipid-4-js" */),
  "component---src-pages-tipid-5-js": () => import("./../../../src/pages/tipid5.js" /* webpackChunkName: "component---src-pages-tipid-5-js" */),
  "component---src-pages-tipid-6-js": () => import("./../../../src/pages/tipid6.js" /* webpackChunkName: "component---src-pages-tipid-6-js" */),
  "component---src-pages-tipid-7-js": () => import("./../../../src/pages/tipid7.js" /* webpackChunkName: "component---src-pages-tipid-7-js" */),
  "component---src-pages-tipid-8-js": () => import("./../../../src/pages/tipid8.js" /* webpackChunkName: "component---src-pages-tipid-8-js" */)
}

